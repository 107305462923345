import { Component } from "react";

class AboutUs extends Component{
    render(){
        return (
            <div className="container">
                 <div className="row pt-1">
          <div className="col-12 col-md-4">
            <img
              src={require("../../assets/images/anupam.jpeg")}
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="col">
            <h1 className="text-center">Anupam Pal</h1>
            <p>
              Anupam Pal hails from the vibrant city of Jamshedpur and has
              devoted himself wholeheartedly to the realm of Indian-style
              painting, infusing his works with exclusive styles and
              unparalleled forms. As an emerging artist, he possesses an
              unyielding passion for revolutionizing artistic culture.
            </p>
            <p>
              Since embarking on his artistic journey, Anupam Pal has woven a
              tapestry of exquisite paintings, each a testament to his boundless
              creativity. His visionary shades and masterful brushstrokes
              converge harmoniously in his two distinct series: the captivating
              'DIAM ART' paintings and the soul-stirring spiritual art pieces.
            </p>
            <p>
              Anupam's artistic process mirrors his dynamic and innovative
              thinking, vividly portrayed in his abstract rendition of the
              'Radha Krishna' theme. Through this portrayal, he captures not
              only the iconic love story but also his own interpretive insights.
            </p>
            <p>
              In his thought-provoking creation 'Conservation,' Anupam delves
              into contemporary issues, shedding light on societal conservatism
              concerning women. This artwork serves as a visual narrative,
              skillfully conveying the prevailing sentiments and attitudes.
            </p>
          </div>
        </div>
            </div>
        );
    }
}

export default AboutUs;