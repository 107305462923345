import { Component } from "react";

class ContactUs extends Component {
    render() {
        return (
            <div className="container">
                <ul>
                <li><span className="fw-bold">Email: </span>anupampal@gmail.com </li>
                        <li><span className="fw-bold">Mobile: </span>+91 79033 78982</li>
                        <li><span className="fw-bold">Address: </span>Jamshedpur</li>
                        <li><span className="fw-bold">Facebook: </span><a href="https://www.facebook.com/anupampal091" >https://www.facebook.com/anupampal091</a></li>
                </ul>
            </div>
        );
    }
}

export default ContactUs;