import { Component } from "react";
import ProductItem from "./ProductItem";
import "./ProductList.css";
class ProductList extends Component {
  render() {
    const productArray = [
      {
        id: 1,
        name: "Glory of the bull 1",
        image: "bull low.jpg",
        category: "Bull",
        price: "100000",
        offerPrice: "90000",
        width: "60",
        height: "36",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 2,
        name: "Glory of the bull 2",
        image: "cow low.jpg",
        category: "Bull",
        price: "110000",
        offerPrice: "90000",
        width: "60",
        height: "36",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 3,
        name: "The Enchanted Symphony of Strings",
        image: "musical band 2023 wm.jpg",
        category: "Music",
        price: "170000",
        offerPrice: "170000",
        width: "108",
        height: "36",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 4,
        name: "Melody of purple",
        image: "melody_of_purple_low.jpg",
        category: "Krishna",
        price: "170000",
        offerPrice: "170000",
        width: "48",
        height: "36",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 5,
        name: "Ganesha",
        image: "ganesha 2022.jpg",
        category: "Ganesha",
        price: "90000",
        offerPrice: "80000",
        width: "48",
        height: "60",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 6,
        name: "The butter flay whisper",
        image: "butterflay.jpg",
        category: "Music",
        price: "90000",
        offerPrice: "80000",
        width: "66",
        height: "36",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 7,
        name: "The butter flay whisper",
        image: "DSC_0585.jpg",
        category: "Music",
        price: "80000",
        offerPrice: "70000",
        width: "48",
        height: "54",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 8,
        name: "Parthasarathi 23",
        image: "525252.jpg",
        category: "Music",
        price: "90000",
        offerPrice: "80000",
        width: "48",
        height: "54",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 9,
        name: "The world on a string",
        image: "world-on-a-string-5.jpg",
        category: "Music",
        price: "90000",
        offerPrice: "70000",
        width: "78",
        height: "36",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 10,
        name: "Tunes of flut",
        image: "10.jpg",
        category: "Krishna",
        price: "90000",
        offerPrice: "80000",
        width: "48",
        height: "36",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 11,
        name: "Friendship",
        image: "friendship 2.jpg",
        category: "Music",
        price: "90000",
        offerPrice: "90000",
        width: "45",
        height: "45",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 12,
        name: "The mesmerizing tune",
        image: "the mesmerizing tunes low.jpg",
        category: "Music",
        price: "80000",
        offerPrice: "70000",
        width: "54",
        height: "48",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 13,
        name: "Face to face",
        image: "face-to-face.jpg",
        category: "Hourse",
        price: "56000",
        offerPrice: "56000",
        width: "48",
        height: "36",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 14,
        name: "Krishna Arjuna",
        image: "krishna-arjuna.jpg",
        category: "Krishna",
        price: "84000",
        offerPrice: "84000",
        width: "36",
        height: "47",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      // {
      //   id: 15,
      //   name: "The Blessing of Durga and Ganesha",
      //   image: "15.jpg",
      //   category: "Ganesha",
      //   price: "95200",
      //   offerPrice: "95200",
      //   width: "48",
      //   height: "60",
      //   inStock: "yes",
      //   description: "Acrylic on canvas",
      // },
      {
        id: 16,
        name: "God of Wisdom",
        image: "16.jpg",
        category: "Ganesha",
        price: "60000",
        offerPrice: "60000",
        width: "36",
        height: "66",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 17,
        name: "Energetic Ember",
        image: "17.jpg",
        category: "Bull",
        price: "60000",
        offerPrice: "60000",
        width: "60",
        height: "36",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 18,
        name: "Celestial Conversations",
        image: "18.jpg",
        category: "music",
        price: "100000",
        offerPrice: "90000",
        width: "60",
        height: "36",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 19,
        name: "Melodic Synchrony",
        image: "19.jpg",
        category: "music",
        price: "60000",
        offerPrice: "80000",
        width: "36",
        height: "36",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 20,
        name: "WHISPERS OF CONNECTION",
        image: "20.jpg",
        category: "music",
        price: "80000",
        offerPrice: "60000",
        width: "36",
        height: "36",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 21,
        name: "Harmonious Ensemble",
        image: "21.jpg",
        category: "music",
        price: "110000",
        offerPrice: "100000",
        width: "78",
        height: "36",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 22,
        name: "A Breath Of Music",
        image: "22.jpg",
        category: "music",
        price: "110000",
        offerPrice: "100000",
        width: "48",
        height: "54",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 23,
        name: "Harmonious Strings",
        image: "23.jpg",
        category: "music",
        price: "120000",
        offerPrice: "110000",
        width: "48",
        height: "60",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 24,
        name: "The Accompanist",
        image: "24.jpg",
        category: "music",
        price: "60000",
        offerPrice: "50000",
        width: "48",
        height: "30",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 25,
        name: "Devi",
        image: "25.jpg",
        category: "durga",
        price: "120000",
        offerPrice: "110000",
        width: "48",
        height: "60",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 26,
        name: "Couple",
        image: "26.jpg",
        category: "durga",
        price: "80000",
        offerPrice: "72000",
        width: "36",
        height: "48",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 27,
        name: "Melody of divine",
        image: "27.jpg",
        category: "ganesha",
        price: "200000",
        offerPrice: "190000",
        width: "96",
        height: "48",
        inStock: "yes",
        description: "Acrylic on canvas",
      },

      {
        id: 28,
        name: "The Mesmerizing Melody",
        image: "28.jpg",
        category: "music",
        price: "168000",
        offerPrice: "160000",
        width: "72",
        height: "48",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 29,
        name: "The Mesmerizing tunes",
        image: "29.jpg",
        category: "music",
        price: "140000",
        offerPrice: "130000",
        width: "48",
        height: "60",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 30,
        name: "A Musical Memoir ",
        image: "30.jpg",
        category: "music",
        price: "126000",
        offerPrice: "120000",
        width: "48",
        height: "54",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 31,
        name: "Agamoni ",
        image: "31.jpg",
        category: "Durga",
        price: "168000",
        offerPrice: "160000",
        width: "72",
        height: "48",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 32,
        name: "The Mesmerizing Tunes 18",
        image: "32.jpg",
        category: "krishna",
        price: "84000",
        offerPrice: "80000",
        width: "36",
        height: "48",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 33,
        name: "Musical band 12",
        image: "33.jpg",
        category: "Music",
        price: "210000",
        offerPrice: "0",
        width: "72",
        height: "60",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 34,
        name: "The Musical Band",
        image: "34.jpg",
        category: "Music",
        price: "126000",
        offerPrice: "0",
        width: "54",
        height: "48",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 35,
        name: "The Musical Band 5",
        image: "35.jpg",
        category: "Music",
        price: "210000",
        offerPrice: "0",
        width: "72",
        height: "60",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 36,
        name: "the butterfly whisper",
        image: "36.jpg",
        category: "Music",
        price: "700000",
        offerPrice: "0",
        width: "36",
        height: "36",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 37,
        name: "The Mesmerizing Tunes 11",
        image: "37.jpg",
        category: "Music",
        price: "75000",
        offerPrice: "0",
        width: "36",
        height: "42",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 38,
        name: "The Mesmerizing Tunes 19",
        image: "38.jpg",
        category: "Krishna",
        price: "84000",
        offerPrice: "0",
        width: "36",
        height: "48",
        inStock: "yes",
        description: "Acrylic on canvas",
      },
      {
        id: 39,
        name: "Rhythmic Exchange ",
        image: "39.jpg",
        category: "music",
        price: "137000",
        offerPrice: "0",
        width: "78",
        height: "36",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 40,
        name: "Musical band 10 ",
        image: "40.jpg",
        category: "music",
        price: "420000",
        offerPrice: "0",
        width: "120",
        height: "60",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 41,
        name: "Musical Theatre ",
        image: "41.jpg",
        category: "music",
        price: "140000",
        offerPrice: "0",
        width: "48",
        height: "60",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 42,
        name: "Where the Rhythm Takes You ",
        image: "42.jpg",
        category: "music",
        price: "140000",
        offerPrice: "0",
        width: "48",
        height: "60",
        inStock: "no",
        description: "Acrylic on canvas",
      },
      {
        id: 43,
        name: "Melody of Purple",
        image: "43.jpg",
        category: "music",
        price: "84000",
        offerPrice: "0",
        width: "36",
        height: "48",
        inStock: "no",
        description: "Acrylic on canvas",
      },

    ];

    const myProductsArray = productArray.map((pItem, i) => {
      const isCategory = toLowercase(pItem.category) === toLowercase(this.props.category) ? true : false;
      return isCategory ? (
        <div className="image-grid">
          <ProductItem product={productArray[i]} key={i} />
        </div>
      ) : null;
    });
    return (
      <div className="pt-1">
        <div className="text-center"><h2>{this.props.category} Series</h2></div>
        <div className="image-main-grid">
          {myProductsArray}
        </div>
      </div>
    );

    function toLowercase(text) {
      if (text !== undefined) {
        return text.toLowerCase();
      }
    }
    /* 
       const myProductsArray = productArray.map((pItem, i) => {
    const isCategory = pItem.category === this.props.category ? true : false;
    return isCategory ? (
      <li className="list-style" >
        <ProductItem product={productArray[i]} key={i} />
      </li>
    ) : null;
  });
  return (
    <div className="pt-1">
      <div><h2>{this.props.category} Series</h2></div>
      <ul className="list-group list-group-horizontal">
        {myProductsArray}
      </ul>
    </div>
  );
    */

  }
}

export default ProductList;
