import { React } from "react";
import "./ProductDetails.css";
import { useSelector } from 'react-redux';
import ProductItem from "./ProductItem";


const ProductDetails = () => {
    const product = useSelector((state) => state.products);
    const recentViews = useSelector((state) => state.recentViews);
    const myRecentViews = recentViews.map((pItem, i) => {
        return (
            <div className="image-grid">
                <ProductItem product={recentViews[i]} key={i} />
            </div>
        )

    });
    return (
        <div className="container">
            <div className="row">
                <div className="col-5 p-1">
                    <img
                        className="rounded img-fluid"
                        src={`/images/${product.image}`}
                        alt="..."
                    ></img>

                </div>
                <div className="col">
                    <h2>{product.name}</h2>
                    <h4>Size: {product.width} W X {product.height} H</h4>
                    {product.inStock==='yes' ? (
                        <p>In Stock</p>
                    ) : (
                        <p style={{ color: 'red' }}>Sold Out</p>
                    )}
                    <h5>Price:
                        <span className="offerPrice">
                            &#x20B9;{product.offerPrice}
                        </span>
                        <span className="price"> &#x20B9;{product.price}</span>
                    </h5>
                    <p>Description: {product.description}</p>
                </div>
            </div>

            <h2 className="text-center">Recent views</h2>
            <div className="image-main-grid">
                {myRecentViews}
            </div>
        </div>
    );
}

export default ProductDetails;