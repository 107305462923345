import { Component } from "react";
import "./ProductItem.css";
import { Link } from 'react-router-dom';
import Sold from "./Sold";
import { connect } from 'react-redux';

class ProductItem extends Component {
  handleImageClick = (product) => {
    // Dispatch an action to set the selected product in the Redux store
    this.props.dispatch({ type: 'SET_PRODUCTS', payload: product });
    this.props.dispatch({ type: 'SET_RECENT_PRODUCTS', payload: product });
  };
  
  render() {
    return (
      <div className="card float-start ">
        <Link to={`/${this.props.product.category}/${this.props.product.name}`}>
          <img
            className="rounded img-fluid"
            src={`/images/${this.props.product.image}`}
            alt="..."
            onClick={() => this.handleImageClick(this.props.product)}
          ></img>
        </Link>
        {this.props.product.inStock === 'no' ? <Sold></Sold> : null}

        <div className="card-footer">
          <div className="fw-bold">{this.props.product.name}</div>
          <div className="row">
            <div className="col">
              <div className="row">
                <div>Size:</div>
              </div>
              <div className="row">
                <div>
                  {this.props.product.width} X {this.props.product.height} Inches
                </div>
              </div>
            </div>
            <div className="col">
              <div className="row">
                <div>Price:</div>
              </div>
              <div className="col">
                <span className="offerPrice">
                  &#x20B9;{this.props.product.offerPrice}
                </span>
                <span className="price"> &#x20B9;{this.props.product.price}</span>
                <span className="discountCSS">
                  {calculateDiscount}% off
                </span>
              </div>
            </div>
          </div>



          <div className="row">
            <span>{this.props.product.description}</span>
          </div>
        </div>
      </div>
    );

    // function getImageUrl(image) {
    //   return require(image).default;
    // }


    function calculateDiscount() {
      return Math.floor(
        ((this.props.product.price - this.props.product.offerPrice) / this.props.product.price) * 100
      );
    }
  }
}

const mapStateToProps = (state) => ({
  products: state.products,
  recentViews: state.recentViews
});

export default connect(mapStateToProps)(ProductItem);


// export default ProductItem;
