import { Component } from "react";
import ProductList from "../product/ProductList";

class GaneshaSeries extends Component{
    render(){
        return (
            <div className="container">
        <div className="row">
          <ProductList category="Ganesha"></ProductList>
        </div>
      </div>
        );
    }
}

export default GaneshaSeries;