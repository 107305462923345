const initialState = {
  products: [],
  recentViews: [],
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PRODUCTS':
      return { ...state, products: action.payload };
    case 'SET_RECENT_PRODUCTS':
      const updatedRecentViews = [action.payload, ...state.recentViews.slice(0, 6)]; // Keep only the latest 5 views
      return {
        ...state,
        recentViews: updatedRecentViews,
      };
    default:
      return state;
  }
};

export default rootReducer;