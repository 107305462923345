import { Component } from "react";
import ProductList from "../product/ProductList";

class DurgaSeries extends Component{
    render(){
        return (
            <div className="container">
        <div className="row">
          <ProductList category="Durga"></ProductList>
        </div>
      </div>
        );
    }
}

export default DurgaSeries;