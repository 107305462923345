import { Component } from "react";
import ProductList from "../product/ProductList";

class BullSeries extends Component{
    render(){
        return (
            <div className="container">
        <div className="row">
          <ProductList category="Bull"></ProductList>
        </div>
      </div>
        );
    }
}

export default BullSeries;