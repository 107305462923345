import React from "react";
import { Component } from "react";
import ProductList from "../product/ProductList";

class Home extends Component {
  imagePath = '../../assets/images';
  render() {
    return (
      <div className="container">
        <div
          id="carouselExampleAutoplaying"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner pt-1">
            <div className="carousel-item active">
              <img
                src={require("../../assets/images/001.jpg")}
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src={require("../../assets/images/002.jpg")}
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src={require("../../assets/images/003.jpg")}
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src={require("../../assets/images/004.jpg")}
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src={require("../../assets/images/005.jpg")}
                className="d-block w-100"
                alt="..."
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <div className="row pt-1">
          <div className="col-12 col-md-4">
            <img
              src={require("../../assets/images/anupam.jpeg")}
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="col">
            <h1 className="text-center">Anupam Pal</h1>
            <p>
              Anupam Pal hails from the vibrant city of Jamshedpur and has
              devoted himself wholeheartedly to the realm of Indian-style
              painting, infusing his works with exclusive styles and
              unparalleled forms. As an emerging artist, he possesses an
              unyielding passion for revolutionizing artistic culture.
            </p>
            <p>
              Since embarking on his artistic journey, Anupam Pal has woven a
              tapestry of exquisite paintings, each a testament to his boundless
              creativity. His visionary shades and masterful brushstrokes
              converge harmoniously in his two distinct series: the captivating
              'DIAM ART' paintings and the soul-stirring spiritual art pieces.
            </p>
            <p>
              Anupam's artistic process mirrors his dynamic and innovative
              thinking, vividly portrayed in his abstract rendition of the
              'Radha Krishna' theme. Through this portrayal, he captures not
              only the iconic love story but also his own interpretive insights.
            </p>
            <p>
              In his thought-provoking creation 'Conservation,' Anupam delves
              into contemporary issues, shedding light on societal conservatism
              concerning women. This artwork serves as a visual narrative,
              skillfully conveying the prevailing sentiments and attitudes.
            </p>
          </div>
        </div>

        <div className="row ">
        <h1 className="text-center">My Recent Work</h1>
          <ProductList></ProductList>
        </div>

        <div className="row pt-1">
          <div className="col-12 col-md-5">
            <img
              src={require("../../assets/images/sonu sood 1.jpg")}
              className="d-block w-100"
              alt="..."
            />
          </div>

            <div className="col">
            <img
              src={require("../../assets/images/sonu sood 2.jpg")}
              className="d-block w-100"
              alt="..."
            />
            <div>
              <h3 className="text-center">A special thanks to Sonu Sood</h3>
              <p>
                A man with golden heart!!A man so down to the earth!! A man who
                provided food and helped thousands of Indian migrants to reach
                their homes who were struck in India and overseas due to
                pandemic Covid-19.All these work of yours inspired me a lot and
                gave rise to my art work "LOCKDOWN HOPE". All it started with my
                sharing the art on the twitter and your replying on my post
                ..that just made my post.And the cherry on the cake was when you
                said that you want to meet me. So finally we met yesterday
                morning . I think it was one of the best moment of my life .I
                never felt even for a moment that i m talking to a superstar.you
                just treated me as ur brother.It was very nice gesture of yours
                when u said that u wanted to keep my piece of art in your
                drawing room.
              </p>
              <p>
                A special thanks to Sonu Sood you for honouring me & with the
                certificate of achievement and also for giving your lovely
                autograph. Thank you for showering all your love,appreciation,
                encouragement and support to the art and the artist like
                me.anupampal.com/
              </p>
            </div>
          </div>
        </div>

        <div className="row pt-1 ">
          <h2 className="text-center">I got Awards</h2>
          <div className="col border border-secondary rounded m-1">
            <p className="p-3 fw-bold">
              Junior Artist of the Year Award from Dipek Art and Trust,
              Jamshedpur (2008)
            </p>
          </div>
          <div className="col border border-secondary rounded m-1">
            <p className="p-3 fw-bold">
              2nd Award in Renison Artist and Writer Association (RAWA) Kolkata
              (2011)
            </p>
          </div>
          <div className="col border border-secondary rounded m-1">
            <p className="p-3 fw-bold">
              3rd Award in 2nd Open Jharkhand Sit & Draw Art Competition,
              Jamshedpur (2010)
            </p>
          </div>
          <div className="col border border-secondary rounded m-1">
            <p className="p-3 fw-bold">
              2nd Award in 4th Open Jharkhand Sit and Draw Art Competition,
              Jamshedpur (2012)
            </p>
          </div>
        </div>
        <div className="row pt-1">
          <div className="col">
            <img
              src={require("../../assets/images/001.jpg")}
              className="d-block w-100"
              alt="..."
            />
          </div>

          <div className="col">
            <img
              src={require("../../assets/images/002.jpg")}
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="col">
            <img
              src={require("../../assets/images/003.jpg")}
              className="d-block w-100"
              alt="..."
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
