import "./App.css";
import Home from "./components/pages/Home";
import AppHeader from "./components/dashboard/Header";
import ContactUs from "./components/pages/ContactUs";
import { Route, Routes } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import MusicSeries from "./components/pages/MusicSeries";
import BullSeries from "./components/pages/BullSeries";
import GaneshaSeries from "./components/pages/GaneshaSeries";
import KrishnaSeries from "./components/pages/KrishnaSeries";
import HourseSeries from "./components/pages/HourseSeries";
import AboutUs from "./components/pages/AboutUs";
import DurgaSeries from "./components/pages/DurgaSeries";
import ProductDetails from "./components/product/ProductDetails";

function App() {
  const isMobileDevice = useMediaQuery({
    query: "(min-device-width: 480px)",
  });

  const isTabletDevice = useMediaQuery({
    query: "(min-device-width: 768px)",
  });

  const isLaptop = useMediaQuery({
    query: "(min-device-width: 1024px)",
  });

  const isDesktop = useMediaQuery({
    query: "(min-device-width: 1200px)",
  });

  const isBigScreen = useMediaQuery({
    query: "(min-device-width: 1201px )",
  });
  return (
    <div>
      <header id="header">
        <AppHeader></AppHeader>
      </header>
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route index element={<Home />}></Route>
        <Route path="/music-series" element={<MusicSeries />} />
        <Route path="/bull-series" element={<BullSeries />} />
        <Route path="/genesha-series" element={<GaneshaSeries />} />
        <Route path="/krishna-series" element={<KrishnaSeries />} />
        <Route path="/durga-series" element={<DurgaSeries/>} />
        <Route path="/hourse-series" element={<HourseSeries />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/:category/:name" element={<ProductDetails/>} />
        {/* <Route path="/:category/:name" render={props => <ProductDetails {...props} products={products} />} /> */}
      </Routes>
    </div>
  );
}

export default App;
