import { Component } from "react";
import './Sold.css';
class Sold extends Component{
    render(){
        return (
            <div className="border dot">
                Sold
            </div>
        );
    }
}

export default Sold;